* {
  box-sizing: border-box;
  font-family: 'Varta', 'Roboto', sans-serif;
}

:root {
  --primary: #3498db;
  --secondary: #ff2d9a;
  --black: #333333;
  --blackLighter: #555555;
  --grayLight: #eeeeee;
  --grayMedium: #dddddd; 
  --white: #FeFeFe;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--black);
}

body {
  --bodyPaddingTop: 94px;
    padding-top: var(--bodyPaddingTop);
}

a {
  color: inherit;
}

#root {
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}

.game {
  overflow: hidden;
}

@media (max-width: 800px) {
  body {
    --bodyPaddingTop: 40px;
    padding-top: var(--bodyPaddingTop);
  }
}

form {
  border: 1px solid var(--white);
  border-radius: 10px;
  padding: 0.5rem;
}